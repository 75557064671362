"use strict";
'kiwi public';

/** @module */
var _typeof3 = require("@babel/runtime-corejs3/helpers/typeof");
var _WeakMap = require("@babel/runtime-corejs3/core-js-stable/weak-map");
var _Object$getOwnPropertyDescriptor = require("@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptor");
var _interopRequireDefault = require("@babel/runtime-corejs3/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = chathistoryMiddleware;
var _concat = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/concat"));
var _promise = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/promise"));
var _typeof2 = _interopRequireDefault(require("@babel/runtime-corejs3/helpers/esm/typeof"));
var _toConsumableArray2 = _interopRequireDefault(require("@babel/runtime-corejs3/helpers/esm/toConsumableArray"));
var _Logger = _interopRequireDefault(require("@/libs/Logger"));
var Misc = _interopRequireWildcard(require("@/helpers/Misc"));
function _getRequireWildcardCache(nodeInterop) { if (typeof _WeakMap !== "function") return null; var cacheBabelInterop = new _WeakMap(); var cacheNodeInterop = new _WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }
function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof3(obj) !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && _Object$getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? _Object$getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }
var log = _Logger.default.namespace('chathistory');

/**
 * Adds the CHATHISTORY IRCv3 spec to irc-framework
 */
function chathistoryMiddleware() {
  return function middleware(client, rawEvents, parsedEvents) {
    client.requestCap('draft/chathistory');
    addFunctionsToClient(client);
    parsedEvents.use(theMiddleware);
  };
  function theMiddleware(command, event, client, next) {
    var _event$command;
    if (command.toLowerCase() === 'batch end chathistory' && client.chathistory) {
      var target = event.params[0];
      client.chathistory.batchCallbacks.resolve(target, event);
    }

    // This is sent as 'unknown command', its been structured this way so hopefully it
    // will still work if a fail handler is ever created in irc-fw
    if ((event === null || event === void 0 ? void 0 : (_event$command = event.command) === null || _event$command === void 0 ? void 0 : _event$command.toLowerCase()) === 'fail' && event.params[0].toLowerCase() === 'chathistory') {
      client.chathistory.batchCallbacks.resolve(event.params[3]);
      if (event.params[1].toLowerCase() === 'invalid_target') {
        // suppress invalid target errors
        return;
      }
    }
    next();
  }
}
function addFunctionsToClient(client) {
  var history = client.chathistory = {};
  history.batchCallbacks = {
    callbacks: Object.create(null),
    queue: [],
    queueActive: false,
    add: function add(cb, target, type) {
      this.callbacks[target.toUpperCase()] = this.callbacks[target.toUpperCase()] || [];
      this.callbacks[target.toUpperCase()].push(cb);
      for (var _len = arguments.length, args = new Array(_len > 3 ? _len - 3 : 0), _key = 3; _key < _len; _key++) {
        args[_key - 3] = arguments[_key];
      }
      this.queue.push({
        target: target,
        type: type,
        args: args
      });
      if (!this.queueActive) {
        this.queueNext();
      }
    },
    resolve: function resolve(target, value) {
      var targetCallbacks = this.callbacks[target.toUpperCase()] || [];
      var cb = targetCallbacks.shift();
      if (!targetCallbacks.length) {
        // last callback, cleanup callbacks
        delete this.callbacks[target.toUpperCase()];
      }
      if (cb) {
        cb(value);
      } else if (history.isSupported()) {
        // inspircd currently does not support chathistory
        // but sends chathistory batches when joining channels
        log.error('chathistory got a resolve but no associated callback');
      }
      this.queueNext();
    },
    queueNext: function queueNext() {
      var _context;
      this.queueActive = true;
      var nextRequest = this.queue.shift();
      if (!nextRequest) {
        this.queueActive = false;
        return;
      }
      client.raw.apply(client, (0, _concat.default)(_context = ['CHATHISTORY', nextRequest.type, nextRequest.target]).call(_context, (0, _toConsumableArray2.default)(nextRequest.args)));
    }
  };

  // supports (ISUPPORT) is used by kiwibnc, the spec and unreal's implementation uses CAP
  history.isSupported = function () {
    return !!client.network.supports('draft/chathistory') || client.network.cap.isEnabled('draft/chathistory');
  };
  history.before = function (target, dateOrTime) {
    return new _promise.default(function (resolve) {
      if (!history.isSupported()) {
        resolve();
        return;
      }
      history.batchCallbacks.add(resolve, target, 'BEFORE', messageReference(dateOrTime), '50');
    });
  };
  history.after = function (target, dateOrTime) {
    return new _promise.default(function (resolve) {
      if (!history.isSupported()) {
        resolve();
        return;
      }
      history.batchCallbacks.add(resolve, target, 'AFTER', messageReference(dateOrTime), '50');
    });
  };
  history.latest = function (target, dateOrTime) {
    return new _promise.default(function (resolve) {
      if (!history.isSupported()) {
        resolve();
        return;
      }
      history.batchCallbacks.add(resolve, target, 'LATEST', messageReference(dateOrTime), '50');
    });
  };
  history.around = function (target, dateOrTime) {
    return new _promise.default(function (resolve) {
      if (!history.isSupported()) {
        resolve();
        return;
      }
      history.batchCallbacks.add(resolve, target, 'AROUND', messageReference(dateOrTime), '50');
    });
  };
  history.between = function (target, fromDateOrTime, toDateOrTime) {
    return new _promise.default(function (resolve) {
      if (!history.isSupported()) {
        resolve();
        return;
      }
      var fromRef = messageReference(fromDateOrTime);
      var toRef = messageReference(toDateOrTime);
      history.batchCallbacks.add(resolve, target, 'BETWEEN', fromRef, toRef, 50);
    });
  };
  function messageReference(inp) {
    if ((0, _typeof2.default)(inp) === 'object') {
      return 'timestamp=' + Misc.dateIso(inp);
    }
    if (inp === '*') {
      return '*';
    }
    return 'msgid=' + inp;
  }
}
window._kiwi_exports = window._kiwi_exports || {};
if(!window._kiwi_exports["libs"]) window._kiwi_exports["libs"] = {};
window._kiwi_exports["libs"]["ChathistoryMiddleware"]
window._kiwi_exports.libs.ChathistoryMiddleware = exports.default ? exports.default : exports;
