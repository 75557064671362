"use strict";

var _interopRequireDefault = require("@babel/runtime-corejs3/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var _includes = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/includes"));
var render = function render() {
  var _context, _context2, _context3;
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', _vm._b({
    staticClass: "kiwi-nicklist-user",
    class: {
      'kiwi-nicklist-user--away': _vm.user.isAway() || _vm.user.isOffline(),
      'kiwi-nicklist-user--ignore': _vm.user.ignore
    }
  }, 'div', _vm.dataAttributes, false), [_vm.nicklist.shouldShowAvatars ? _c('div', {
    staticClass: "kiwi-nicklist-avatar"
  }, [_c('UserAvatar', _vm._b({
    attrs: {
      "user": _vm.user,
      "network": _vm.network,
      "size": "small"
    }
  }, 'UserAvatar', _vm.nicklist.avatarProps, false))], 1) : _c('AwayStatusIndicator', {
    staticClass: "kiwi-nicklist-awaystatus",
    attrs: {
      "network": _vm.network,
      "user": _vm.user,
      "toggle": false
    }
  }), _c('span', {
    staticClass: "kiwi-nicklist-user-prefix"
  }, [_vm._v(_vm._s(_vm.userModePrefix))]), _c('span', {
    staticClass: "kiwi-nicklist-user-nick",
    style: {
      color: _vm.userColour
    },
    on: {
      "click": function click($event) {
        $event.stopPropagation();
        return _vm.nicklist.openUserbox(_vm.user);
      }
    }
  }, [_vm._v(_vm._s(_vm.user.nick) + " "), _c('span', [(0, _includes.default)(_context = this.user.realname).call(_context, 'irc.chateachat.com') ? _c('i', {
    staticClass: "fa fa-video-camera",
    staticStyle: {
      "padding-left": "10px"
    },
    attrs: {
      "aria-hidden": "true"
    },
    on: {
      "click": function click($event) {
        $event.stopPropagation();
        return _vm.nicklist.requestPermision(_vm.user, 'public');
      }
    }
  }) : _vm._e()]), _c('span', {
    staticStyle: {
      "color": "red"
    }
  }, [(0, _includes.default)(_context2 = _vm.cams.watchingUsers).call(_context2, _vm.user.nick) ? _c('i', {
    staticClass: "fa fa-eye",
    staticStyle: {
      "padding-left": "10px"
    },
    attrs: {
      "aria-hidden": "true"
    },
    on: {
      "click": function click($event) {
        $event.stopPropagation();
        return _vm.nicklist.closeByEye(_vm.user);
      }
    }
  }) : _vm._e()]), _c('span', {
    staticStyle: {
      "color": "red"
    }
  }, [(0, _includes.default)(_context3 = this.user.realname).call(_context3, 'CAMPRIVADA') ? _c('i', {
    staticClass: "fa fa-video-camera",
    staticStyle: {
      "padding-left": "10px"
    },
    attrs: {
      "aria-hidden": "true"
    },
    on: {
      "click": function click($event) {
        $event.stopPropagation();
        return _vm.nicklist.requestPermision(_vm.user, 'private');
      }
    }
  }) : _vm._e()])]), _c('div', {
    staticClass: "kiwi-nicklist-user-buttons"
  }, [_c('svg', {
    staticClass: "kiwi-nicklist-user-typing",
    class: {
      'kiwi-nicklist-user-typing--active': _vm.userTypingState === 'active',
      'kiwi-nicklist-user-typing--paused': _vm.userTypingState === 'paused'
    },
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  }, [_c('circle', {
    attrs: {
      "cx": "4",
      "cy": "12",
      "r": "3"
    }
  }), _c('circle', {
    attrs: {
      "cx": "12",
      "cy": "12",
      "r": "3"
    }
  }), _c('circle', {
    attrs: {
      "cx": "20",
      "cy": "12",
      "r": "3"
    }
  })]), _c('svg', {
    staticClass: "kiwi-nicklist-user-message",
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 36 36"
    },
    on: {
      "click": function click($event) {
        $event.stopPropagation();
        return _vm.nicklist.openQuery(_vm.user);
      }
    }
  }, [_c('path', {
    attrs: {
      "d": "M18 1C8.059 1 0 7.268 0 15c0 4.368 2.574 8.268 6.604 10.835C6.08 28.144\n                    4.859 31.569 2 35c5.758-.96 9.439-3.761 11.716-6.416c1.376.262 2.805.416\n                    4.284.416c9.941 0 18-6.268 18-14S27.941 1 18 1z"
    }
  })])])], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;